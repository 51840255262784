import request from "~/utils/http";

const reportApi = {
	affiliates : async (params) => {
		const url = 'v1/report/affiliates';
		return await request.get(url, {params});
	},
	affiliatesChart : async (id) => {
		const url = `v1/report/affiliates/chart/${id}`;
		return await request.get(url);
	},
	affiliatesExport : async (params) => {
		const url = 'v1/report/affiliates/export';
		return await request.post(url, params);
	},
	affiliatesExportOrder : async (id) => {
		const url = `v1/report/affiliates/export-order/${id}`;
		return await request.post(url);
	},
	affiliatesExportUser : async (id) => {
		const url = `v1/report/affiliates/export-user/${id}`;
		return await request.post(url);
	},

	users : async (params) => {
		const url = 'v1/report/users';
		return await request.get(url, {params});
	},
	usersExport : async (params) => {
		const url = 'v1/report/users/export';
		return await request.post(url, params);
	},
	coin : async (params) => {
		const url = 'v1/report/coins';
		return await request.get(url, {params});
	},
	coinChart : async (id) => {
		const url = `v1/report/coins/chart/${id}`;
		return await request.get(url);
	},
	coinExport : async (params) => {
		const url = 'v1/report/coins/export';
		return await request.post(url, params);
	},
	coinExportOrder : async (id) => {
		const url = `v1/report/coins/export-order/${id}`;
		return await request.post(url);
	},
	coinExportUser : async (id) => {
		const url = `v1/report/coins/export-user/${id}`;
		return await request.post(url);
	},
};

export default reportApi;