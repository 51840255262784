import FontAwesomeIcon from "~/components/FontAwesome";
import _ from "lodash"
import {Link} from "react-router-dom";
import {useCan} from "../../hooks";

export const NavBarData = () => {

	const order = {
		import  : useCan('shop_order_import'),
		branch  : useCan('shop_branch'),
		history : useCan('shop_orderHistory_all'),
	}
	const cod = {
		cod  : useCan('shop_cod'),
		tabOrder: useCan('shop_cod_order'),
		tabWaiting: useCan('shop_cod_waiting_pay'),
		tabSuccess: useCan('shop_cod_success_pay'),
	}
	const customer = {
		customer  : useCan('shop_customer'),
	}
	const postOffice = {
		postOffice  : useCan('shop_postOffice'),
	}
	const ticket = {
		ticket  : useCan('shop_ticket'),
	}
	const admin = {
		shippingPrice  : useCan('shop_shipping_price'),
		users  : useCan('shop_user'),
		usersVerify  : useCan('shop_user_verify'),
		usersBlockOrder  : useCan('shop_user_block_order'),
		roles  : useCan('shop_roles'),
		discount  : useCan('shop_discount'),
	}

	const report = {
		affiliate  : useCan('shop_report_affiliate'),
		user  : useCan('shop_report_user'),
	}

	let codUrl = '/cod/order';

	if(cod.cod) {
		if(!cod.tabOrder) {
			codUrl = '/cod/waiting-pay'
		}
		if(!cod.tabOrder && !cod.tabWaiting) {
			codUrl = '/cod/success'
		}
	}

	const navBarList = [
		{
			key: 'home',
			label: <Link to={'/'}>Dashboard</Link>,
			to: '/',
			title: 'Dashboard',
			icon: <FontAwesomeIcon icon="fa-light fa-house"/>,
			count: 0,
		},
		{
			key: 'orderGroup',
			label: 'Quản lý Đơn hàng',
			icon: <FontAwesomeIcon icon="fa-light fa-clipboard-list-check"/>,
			to: '#',
			title: 'Quản lý Đơn hàng',
			count: 0,
			children: [
				{
					key: 'order',
					to: '/order',
					title: 'Đơn hàng',
					label: <Link to={'/order'}>Đơn hàng</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-clipboard-list-check"/>,
				},
				{
					key: 'import',
					to: '/order/import',
					title: 'Lên đơn Excel',
					label: <Link to={'/order/import'}>Lên đơn Excel</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-file-import"/>,
				},
				{
					key: 'importHistory',
					to: '/order/import-history',
					title: 'Lịch sử lên đơn',
					label: <Link to={'/order/import-history'}>Lịch sử lên đơn Excel</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-file-import"/>,
				},
				{
					key: 'history',
					to: '/order/history',
					title: 'Lịch sử đơn',
					label: <Link to={'/order/history'}>Lịch sử đơn</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-timeline-arrow" />,
				},
				{
					key: 'branch',
					to: '/branch',
					title: 'Điểm lấy hàng',
					label: <Link to={'/branch'}>Điểm lấy hàng</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-shop"/>,
				}
			]
		},
		{
			key: 'cod',
			to: codUrl,
			title: 'Đối soát - COD',
			label: <Link to={codUrl}>Đối soát - Thanh toán COD</Link>,
			icon: <FontAwesomeIcon icon="fa-light fa-sack-dollar"/>,
		},
		{
			key: 'customer',
			to: '/customer',
			title: 'Khách hàng',
			label: <Link to={'/customer'}>Khách hàng</Link>,
			icon: <FontAwesomeIcon icon="fa-light fa-user"/>,
		},
		{
			key: 'postOffice',
			to: '/post-office',
			title: 'Bưu cục',
			label: <Link to={'/post-office'}>Bưu cục</Link>,
			icon: <FontAwesomeIcon icon="fa-light fa-location-pen" />,
		},
		{
			key: 'ticket',
			to: '/ticket',
			title: 'Phản hồi',
			label: <Link to={'/ticket'}>Phản hồi</Link>,
			icon: <FontAwesomeIcon icon="fa-light fa-money-check-dollar-pen"/>,
		},
		{
			key: 'adminGroup',
			label: 'Quyền admin',
			icon: <FontAwesomeIcon icon="fa-light fa-crown" />,
			children: [
				{
					key: 'shippingPrice',
					to: '/shipping-price',
					title: 'Bảng giá',
					label: <Link to={'/admin/shipping-price'}>Quản lý bảng giá</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-circle-dollar" />,
				},
				{
					key: 'users',
					to: '/users',
					title: 'Thành viên',
					label: <Link to={'/users'}>Quản lý thành viên</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-users-medical"/>,
				},
				{
					key: 'usersVerify',
					to: '/users/verify',
					title: 'Thành viên',
					label: <Link to={'/users/verify/underReview'}>Yêu cầu xác thực</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-user-shield" />,
				},
				{
					key: 'usersBlockOrder',
					to: '/users/block-order',
					title: 'Thành viên',
					label: <Link to={'/users/block-order'}>khóa lên đơn</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-user-shield" />,
				},
				{
					key: 'discount',
					to: '/discount/running',
					title: 'Mã Khuyến mãi',
					label: <Link to={'/discount/running'}>Mã Khuyến mãi</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-tags" />,
				},
				{
					key: 'roles',
					to: '/admin/roles',
					title: 'Phân quyền',
					label: <Link to={'/admin/roles'}>Phân quyền</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-diagram-project" />,
				}
			]
		},
		{
			key: 'reportGroup',
			label: 'Báo cáo',
			icon: <FontAwesomeIcon icon="fa-light fa-chart-mixed-up-circle-dollar" />,
			children: [
				{
					key: 'reportAffiliate',
					to: '/report/affiliate',
					title: 'Báo cáo CTV',
					label: <Link to={'/report/affiliate'}>Báo cáo CTV</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-circle-dollar" />,
				},
				{
					key: 'reportCoin',
					to: '/report/coin',
					title: 'Báo cáo Xu',
					label: <Link to={'/report/coin'}>Báo cáo Xu</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-circle-dollar" />,
				},
				{
					key: 'reportUser',
					to: '/report/users',
					title: 'Báo cáo thành viên',
					label: <Link to={'/report/users'}>Báo cáo thành viên</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-users-medical"/>,
				},
				{
					key: 'reportOps',
					to: '/report/ops',
					title: 'Báo cáo vận hành',
					label: <Link to={'/report/ops'}>Báo cáo vận hành</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-diagram-project" />,
				},
				{
					key: 'reportMoney',
					to: '/report/cash-flow',
					title: 'Báo cáo dòng tiền',
					label: <Link to={'/report/cash-flow'}>Báo cáo dòng tiền</Link>,
					icon: <FontAwesomeIcon icon="fa-light fa-diagram-project" />,
				}
			]
		},
	];

	function removeItem(navBarList, itemKey) {
		_.remove(navBarList, function(item) {
			return item.key === itemKey;
		});
	}

	function removeSubItem(navBarList, itemIndex, subItemKey) {
		_.remove(navBarList[itemIndex]?.children, function(item) {
			return item.key === subItemKey;
		});
	}

	_.forEach(navBarList, function(menu, index) {
		if(menu.key === 'orderGroup') {
			if(!order.import) {
				removeSubItem(navBarList, index, 'import')
				removeSubItem(navBarList, index, 'importHistory')
			}
			if(!order.branch) {
				removeSubItem(navBarList, index, 'branch')
			}
			if(!order.history) {
				removeSubItem(navBarList, index, 'history')
			}
		}
		if(menu.key === 'reportGroup') {
			if(!report.affiliate) {
				removeSubItem(navBarList, index, 'reportAffiliate')
			}
			if(!report.affiliate) {
				removeSubItem(navBarList, index, 'reportCoin')
			}
			if(!report.user) {
				removeSubItem(navBarList, index, 'reportUser')
			}
		}
	});

	if(!cod.cod) {
		removeItem(navBarList, 'cod')
	}
	if(!customer.customer) {
		removeItem(navBarList, 'customer')
	}
	if(!postOffice.postOffice) {
		removeItem(navBarList, 'postOffice')
	}
	if(!ticket.ticket) {
		removeItem(navBarList, 'ticket')
	}
	if(!ticket.ticket) {
		removeItem(navBarList, 'ticket')
	}
	if(!admin.shippingPrice && !admin.users && !admin.roles && !admin.usersVerify && !admin.discount && !admin.usersBlockOrder) {
		removeItem(navBarList, 'adminGroup')
	}
	else {
		_.forEach(navBarList, function(menu, index) {
			if(menu.key === 'adminGroup') {
				if(!admin.shippingPrice) {
					removeSubItem(navBarList, index, 'shippingPrice')
				}
				if(!admin.users) {
					removeSubItem(navBarList, index, 'users')
				}
				if(!admin.usersVerify) {
					removeSubItem(navBarList, index, 'usersVerify')
				}
				if(!admin.usersBlockOrder) {
					removeSubItem(navBarList, index, 'usersBlockOrder')
				}

				if(!admin.roles) {
					removeSubItem(navBarList, index, 'roles')
				}
				if(!admin.discount) {
					removeSubItem(navBarList, index, 'discount')
				}
			}
		});
	}

	_.forEach(navBarList, function(menu, index) {
		if (menu?.children && menu?.children.length === 1) {
			navBarList[index].label = menu?.children[0].label;
			navBarList[index].icon = menu?.children[0].icon;
			navBarList[index].children = undefined;
		}
	});

	return navBarList;
}